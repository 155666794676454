@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
@import url('http://fonts.cdnfonts.com/css/akrobat');
@import 'normalize.css';
@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-calendars/styles/material.css';
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../node_modules/@syncfusion/ej2-gantt/styles/material.css';
@import '../node_modules/@syncfusion/ej2-grids/styles/material.css';
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../node_modules/@syncfusion/ej2-layouts/styles/material.css';
@import '../node_modules/@syncfusion/ej2-lists/styles/material.css';
@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-richtexteditor/styles/material.css';
@import '../node_modules/@syncfusion/ej2-treegrid/styles/material.css';


/** Defaults */
* {
  margin: 0;
  padding: 0;
  border: none;
  position: relative;
}

html,
body {
  height: 100vh;
  width: 100vw;
  color: black;
  overflow: hidden;
  font-size: 14px; /** 1em ou 1rem === 14px */
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
}
/** Defaults */

/** card-titulo */
.card-titulo {
  color: #000 !important;
  background: #FFF !important;
}

/** content */
.content {
  box-shadow: inset -2px 0px 2px #00000012 !important;
}

/** CardFilters **/
/** botão filtrar */

/** card-filters-filtrar */
.card-filters-filtrar button {
  border-radius: 16px !important;
  background: rgba(96, 209, 243, 0.25) !important;
  padding: 6px 18px !important;
  font-size: 14px !important;
  color: #0077CF !important;
  text-align: center;
  height: 32px !important;
  min-width: 98px !important;
  font-weight: 600 !important;
  font-family: 'Open sans' !important;
  bottom: 6px !important;
}

/** label filtros */
.expanded-icon-label {
  font-size: 18px !important;
  margin-right: 10px !important;
}

/** margem do header */
.card-filters-header {
  margin: 0px !important;
}

/** espaçamento botões de ação */
.card-filters-actions .MuiButton-root {
  padding: 0px !important;
  min-width: 24px !important;
  margin-right: 27px !important;
}

/** container dos inputs */
.card-filters-inputs-container {
  padding: 20px 25px 0 !important;
  height: 97px !important;
}

/** tamanho dos inputs */
.card-filters-inputs-container .MuiGrid-item {
  padding-right: 27px !important;
}

/** altera o primeiro select */
.card-filters-tipo-filtro {
  flex-basis: 0% !important;
  min-width: 202px !important;
}

.card-filters-clean {
  color: #F7375C !important;
}

.card-filters-footer {
  padding: 0 25px 12px 25px !important;
}

/** adicionando margens aos chips quando o cardFilters está aberto */
.MuiAccordionActions-spacing .MuiChip-root {
  margin: 0px 6px 6px !important;
}

/** label filtros adicionados */
.label-filter-chips-closed {
  margin-bottom: 6px !important;
  margin-right: 6px !important;
  color: #95A2B9 !important;
  font-size: 12px !important;
  line-height: 17px !important;
}

/* altura do header */
.card-filters-header {
  min-height: 48px !important;
  max-height: 48px!important;
  padding: 0 25px !important;
}

/** label do chip */
.MuiChip-root span {
  font-size: 12px;
  font-family: 'Open Sans';
  color: #33384F !important;
  line-height: 17px !important;
}

/** titulo do chip */
.MuiChip-root b {
  font-weight: bold;
}

/** CardFilters **/

/** DataTable */
.ag-row-selected {
  border-bottom: 1px solid #0E95EF !important;
  background-color: rgba(96, 209, 243, 0.12) !important;
}

.ag-row-odd:not(.ag-row-selected) {
  background-color: rgba(195, 210, 227, 0.12) !important;
  border-bottom: 1px solid #C3D2E3 !important;
}

.ag-row-even:not(.ag-row-selected) {
  background-color: #FFFFFF !important;
  border-bottom: 1px solid #C3D2E3 !important;
}

/** botão de editar */
.button-grid {
  margin-right: 30px !important;
}

/** sombra da tabela */
.tabela .MuiPaper-elevation {
  box-shadow: none !important;
}

/** borda paginação */
.MuiTableCell-root {
  border: none !important;
}

/* fonte */
.ag-header-cell-text {
  font-family: 'Open Sans' !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  line-height: 19px !important;
}

/** border-bottom da tabela */
.ag-theme-material {
  border-bottom: 2px solid #C3D2E3 !important;
  box-shadow: none !important;
}

.ag-cell-label-container {
  height: 100%;
  font-size: 14px !important;
  font-weight: 400;
  cursor: pointer;
  color: #95A2B9;
  opacity: 1 !important;
  margin-bottom: 11px !important;
}

.ag-header-row {
  border: none !important;
  box-sizing: border-box !important;
  border-width: 0px 0px 2px !important;
  padding-top: 15px !important;
  height: 50px !important;
}

.MuiButton-containedPrimary .MuiButton-disableElevation {
  margin-right: 30px !important;
}

.ag-cell-not-inline-editing {
  color: #1B1D26 !important;
  font-family: 'Open Sans';
}

.ag-header-viewport {
  border-bottom: 2px solid #C3D2E3 !important;
  background-color: rgba(195, 210, 227, 0.25) !important;
  border-radius: 0px !important;
  border-top: 1px solid #C3D2E3 !important;
  margin: 0px 23px !important;
  height: 50px !important;
}

.ag-header-active {
  background-color: transparent !important;
}

.MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows {
  font-family: 'Open Sans' !important;
  color: #5B6478 !important;
  font-size: 12px !important;
  line-height: 17px !important;
}

.ag-header {
  border: none !important;
  min-height: 50px !important;
  height: 50px !important;
}

.ag-body-viewport {
  margin: 0px 23px !important 
}

.ag-paging-panel {
  border-style: solid !important;
  border-color: rgba(0, 0, 0, 0.1) !important;
  box-sizing: border-box !important;
  border-width: 0px 0px 2px !important;
}
/** DataTable */

/* Custom Scrollbar */
/* width */
::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(9, 9, 9, 0);
  border-radius: 0px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.3);
  border-radius: 0px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.6);
}
/* Custom Scrollbar */
